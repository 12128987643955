import type { UIAnalyticsEvent as GasV3AnalyticsEvent } from '@atlaskit/analytics-next';

export type AnalyticsEventMeta = {
	analyticsEvent: GasV3AnalyticsEvent;
};

export type GasV3AnalyticsEventTrigger = (
	analyticsEvent: GasV3AnalyticsEvent,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metaData?: any,
) => void;

export const PRODUCT_ANALYTICS_EVENT_NAMES = {
	// Event Type - UI
	ISSUE_SEARCH_ICON_CLICK: 'jiraPortfolio3IssueSearch searchIconClick',
	ISSUE_SEARCH_STARTED_SEARCH_RESULT_NAVIGATION:
		'jiraPortfolio3IssueSearch startedSearchResultsNavigation',
	REPARENT_ISSUES: 'issues reparented',
	REVERT_SCENARIO: 'scenarioChanges reverted',
	SELECT_TIMELINE_RANGE: 'timelineOption selectTimelineRange',
	SHARE_REPORT: 'report shared',
	UPDATE_ISSUE: 'issue updated',
	UPDATE_ISSUES: 'issues updated',
	UPDATE_FILTER: 'filters updated',
	CLEAR_FILTERS: 'filters cleared',
	UPDATED_DEPENDENCIES_FILTER: 'dependenciesFilter updated',
	UPDATED_ISSUE_PRIORITIES_FILTER: 'issuePrioritiesFilter updated',
	UPDATED_DEPENDENCY_STYLE: 'dependencyStyle updated',
	SET_ACTIVE_VIEW: 'activeView changed',
	SAVE_VIEW: 'view saved',
	ADD_VIEW: 'view added',
	RESET_VIEWS: 'view reset',
	HIDE_COLUMN: 'column hidden',
	SHOW_COLUMN: 'column shown',
	MOVE_COLUMN: 'column moved',
	MARK_AS_DEFAULT_VIEW: 'view defaulted',
	CLICKED_DEPENDENCY_TAB: 'dependencyTab clicked',
	VIEWED_DEPENDENCY_TAB: 'dependencyTab viewed',
	CLICKED_DEPENDENCY_CARD: 'dependencyCard clicked',
	CLICKED_DEPENDENCY_LINE: 'dependencyLink clicked',
	UPDATED_DEPENDENCY_ROLLUP_DROPDOWN: 'dependencyRollupDropDown updated',
	UPDATED_DEPENDENCY_GROUP_DROPDOWN: 'dependencyGroupDropDown updated',
	UPDATED_DEPENDENCY_ISSUE_FILTER: 'dependencyIssueFilter updated',
	UPDATED_DEPENDENCY_PROJECT_FILTER: 'dependencyProjectFilter updated',
	UPDATED_DEPENDENCY_ISSUE_LINK_TYPE_FILTER: 'dependencyIssueLinkTypeFilter updated',
	UPDATED_DEPENDENCY_SPRINT_FILTER: 'dependencySprintFilter updated',
	CLICKED_EXPORT_TO_CSV: 'exportToCsv clicked',
	OPENED_WARNING_CENTER: 'warningCenter opened',
	FILTERED_WARNING_TYPE: 'warningAffectsLink clicked',
	LOCATED_WARNING_ISSUE_ITEM: 'warningIssueItem clicked',
	TOGGLED_WARNING_FILTER: 'warningsFilterSwitch clicked',
	TOGGLED_WARNING_TYPE: 'warningTypeToggle clicked',
	OPENED_DEPENDENCY_FLYOUT_FROM_DROPDOWN: 'dependencyFlyOut openedFromDropdown',
	OPENED_DEPENDENCY_FLYOUT_FROM_BADGE: 'dependencyFlyOut openedFromBadge',
	EXPANDED_ALL_ISSUES: 'expandAllIssues clicked',
	COLLAPSED_ALL_ISSUES: 'collapseAllIssues clicked',
	ADDED_ISSUE_DEPENDENCY_LINK: 'issueDependencyLink added',
	DELETED_ISSUE_DEPENDENCY_LINK: 'issueDependencyLink deleted',
	SWAPPED_ISSUE_DEPENDENCY_LINK: 'issueDependencyLink swapped',
	CREATED_ISSUE_FROM_HEADER: 'createIssueFromHeader clicked',
	CREATED_ISSUE_INLINE: 'createIssueInline clicked',
	OPENED_ISSUE_SUMMARY: 'issueSummary opened',
	UPDATED_ISSUE_SUMMARY: 'issueSummary updated',
	CANCELLED_ISSUE_SUMMARY: 'issueSummary cancelled',
	CLICKED_ISSUE_SUMMARY_BUTTON: 'issueSummaryButton clicked',
	CLICKED_SELECT_ISSUES: 'selectIssues clicked',
	CLICKED_CANCEL_OPTIMIZATION: 'cancelOptimization clicked',
	CLICKED_ACCEPT_OPTIMIZATION: 'acceptOptimization clicked',
	CLICKED_FULL_SCREEN_TIMELINE_BUTTON: 'fullScreenTimelineBtn clicked',
	BULK_ACTIONS_CLICKED: 'bulkActions clicked',
	BULK_ACTIONS_OPTION_SELECTED: 'bulkActions optionSelected',
	BULK_ACTIONS_ACTION_SUCCEEDED: 'bulkActions actionSucceeded',
	OPENED_SPRINT_FLYOUT: 'sprintFlyout opened',
	OPENED_RELEASES_FLYOUT: 'releasesFlyout opened',
	VIEWED_SPRINT_IN_JIRA: 'sprintInJira viewed',
	VIEWED_SPRINT_REPORT_IN_JIRA: 'sprintReportInJira viewed',
	APPLIED_SPRINT_FILER_FROM_FLYOUT: 'sprintFilterFromSprintFlyout applied',
	SPRINT_CAPACITY_EDITED: 'sprintCapacity edited',
	SPRINT_VELOCITY_CHART_VIEWED: 'velocityChartInJira viewed',
	SHARED_TEAM_ADDED: 'sharedTeam added',
	TEAM_IS_SHARED: 'team shared',
	ATLAS_TEAM_CREATED: 'atlasTeam created',
	ATLAS_TEAM_ADDED: 'atlasTeam added',
	PRIVATE_TEAM_CREATED: 'privateTeam created',
	EXPAND_TOOL_BAR: 'expandToolBar clicked',
	COLLAPSE_TOOL_BAR: 'collapseToolBar clicked',
	START_EDIT_DESCRIPTION: 'descriptionEditor activated',
	CANCEL_EDIT_DESCRIPTION: 'descriptionEditor deactivated',
	SAVE_DESCRIPTION: 'descriptionEditor saved',
	ALIGN_RELEASE_DATES_IN_CPR: 'alignDatesOfReleasesInCrossProjectRelease clicked',
	VIEW_RELEASE_IN_ROADMAP: 'viewReleaseInRoadmap clicked',
	ADD_RELEASE_TO_CPR: 'addReleaseToCrossProjectRelease clicked',
	CONFIGURABLE_DATE_CHANGED: 'configurableDate changed',
	CUSTOM_CONFIGURABLE_DATE_USED: 'customConfigurableDate used',
	NO_CUSTOM_CONFIGURABLE_DATES_USED: 'noCustomConfigurableDates used',
	FILTERED_RELEASES_TAB: 'releasesTab filtered',
	INFERRED_DATE_SELECTION_CHANGED: 'inferredDateSelection changed',
	HEADER_HELP_LINK_CLICKED: 'headerHelpLink clicked',
	SAVE_SELECTED_CHANGES_IN_JIRA_CLICKED: 'saveSelectedChangesInJira clicked',
	DISCARD_SELECTED_CHANGES_CLICKED: 'discardSelectedChanges clicked',
	REVIEW_CHANGES_MODAL_CANCEL_CLICKED: 'cancel clicked',
	INLINE_DIALOG_VIEWED: 'inlineDialog viewed',
	REVIEW_CHANGES_MODAL_QUICK_SELECT_CLICKED: 'reviewChangesQuickSelect clicked',
	REVIEW_CHANGES_MODAL_USER_FILTER_CHANGED: 'reviewChangesUserFilter changed',
	EXPAND_SCOPE_GROUP: 'expandScopeGroup clicked',
	COLLAPSE_SCOPE_GROUP: 'collapseScopeGroup clicked',
	EXPAND_SCOPE_ITEM: 'expandScopeItem clicked',
	COLLAPSE_SCOPE_ITEM: 'collapseScopeItem clicked',
	CLOSE_UNSAVED_CHANGES_SPOTLIGHT: 'closeUnsavedChangesSpotlight clicked',
	/**
	 * With actionSubjectId
	 */
	COLOR_BY_CLICKED: 'dropdownItem clicked colorBy',
	SORT_BY_CLICKED: 'dropdownItem clicked sortBy',
	GROUP_BY_CLICKED: 'dropdownItem clicked groupBy',
	SHOW_CAPACITY_ON_TIMELINE_CHANGED: 'checkbox changed showCapacityOnTimeline',
	DEPENDENCY_TYPE_CLICKED: 'dropdownItem changed dependencyStyle',
	ROLL_UP_DATES_CHANGED: 'checkbox changed rollUpDates',
	ROLL_UP_OTHERS_CHANGED: 'checkbox changed rollUpOthers',
	EXPORT_PNG_CHANGE_TIMELINE_WIDTH: 'button clicked exportPngTimelineWidth',
	EXPORT_PNG_MODAL_CLOSED: 'modal closed exportPng',
	EXPORT_CSV_MODAL_CLOSED: 'modal closed exportCsv',

	// Event Type - Track
	CALCULATE_PLAN: 'plan calculated',
	DELETE_PLAN: 'plan deleted',
	STAR_PLAN: 'plan starred',
	UNSTAR_PLAN: 'plan unstarred',
	CREATE_CROSS_PROJECT_RELEASE: 'crossProjectRelease created',
	REPARENT_ISSUE: 'issue reparented',
	UPDATE_JIRA: 'changes updatedInJira',
	ADD_ISSUE: 'issue added',
	RANK_ISSUE: 'issue ranked',
	PLAN_EXPORTED: 'plan exported',
	ADD_LABEL: 'label added',

	// Event Type - Operational
	RESET_BACKLOG: 'backlog reset',
	COMMIT_MODAL_LOADED_CHANGES: 'commitModal loadedChanges',
	COMMIT_MODAL_SCROLLED: 'commitModal scrolled',
	TIMELINE_VERTICAL_SCROLLED: 'timelineVerticalScrollPerformance measured',
} as const;
