import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	readOnlyLozengeText: {
		id: 'plans.dashboards.main.content.cells.plan-cell.read-only-lozenge-text',
		defaultMessage: 'Read-Only',
		description:
			'A label for the Read-only lozenge displayed next to the plan title when the plan is in read-only mode.',
	},
	untitledPlan: {
		defaultMessage: 'Untitled plan',
		id: 'plans.dashboards.main.content.cells.plan-cell.untitled-plan',
		description: 'Default title for an Advanced Roadmap plan',
	},
});
