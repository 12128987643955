import React from 'react';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import FilterSearch from '@atlassian/jira-common-analytics-v2-wrapped-components/src/filter-search';
import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import type { FilterViewProps } from '@atlassian/jira-directory-base/src/model';
import { useIntl } from '@atlassian/jira-intl';
import { usePlans } from '../../../controllers';
import messages from './messages';

const FilterSearchWithAnalytics = withFireUiAnalytics({
	onChange: 'filterSearch',
})(FilterSearch);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onFilter }: FilterViewProps<Filter>) => {
	const { filter } = usePlans();
	const { formatMessage } = useIntl();
	const handleQueryChange = (value: string) => {
		onFilter({ text: value });
	};

	return (
		/* @ts-expect-error - Type '{ increasedWidth: true; placeholderAlwaysVisible: true; isAlwaysExpanded: true; value: string; onChange: (value: string) => void; }' is missing the following properties from type 'Omit<{ value: string; placeholder: string; describedById?: string | undefined; hasAutoFocus: boolean; isCompact: boolean; onChange: (value: string, args_1: UIAnalyticsEvent) => void; ... 6 more ...; key?: Key | ... 1 more ... | undefined; }, keyof WithAnalyticsEventsProps>': placeholder, hasAutoFocus, isCompact */
		<FilterSearchWithAnalytics
			increasedWidth
			placeholderAlwaysVisible
			isAlwaysExpanded
			value={filter}
			onChange={handleQueryChange}
			ariaLabelText={formatMessage(messages.searchFieldArialLabel)}
		/>
	);
};
