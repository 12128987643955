import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirmDeletePlanHeading: {
		id: 'plans.dashboards.main.content.delete-modal.confirm-delete-plan-heading',
		defaultMessage: 'Delete plan',
		description: 'Heading for the delete plan dialog',
	},
	confirmDeletePlanMessage: {
		id: 'plans.dashboards.main.content.delete-modal.confirm-delete-plan-message',
		defaultMessage:
			'This operation will permanently delete the plan <b>{title}</b>. Are you sure you want to do this?',
		description: 'Message for the delete plan dialog',
	},
	confirm: {
		id: 'plans.dashboards.main.content.delete-modal.confirm',
		defaultMessage: 'Confirm',
		description: 'Label for the confirm button on the delete plan dialog',
	},
	cancel: {
		id: 'plans.dashboards.main.content.delete-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the cancel button on the delete plan dialog',
	},
});
