import type { FlagConfiguration } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'plans.dashboards.main.content.delete-modal.flags.success.title',
		defaultMessage: '{name} was deleted',
		description:
			'Title of the flag shown in Plans Directory when the request for deleting a plan succesed',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (name: string): FlagConfiguration => ({
	type: 'success',
	title: [messages.title, { name }],
	isAutoDismiss: true,
});
