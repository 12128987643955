import React from 'react';
import { styled } from '@compiled/react';
import ErrorState from '@atlassian/jira-common-components-error-state';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const ErrorScreen = () => {
	const { formatMessage } = useIntl();

	const onRetry = () => {
		window.location.reload();
	};

	return (
		<Center>
			<ErrorState
				header={formatMessage(messages.header)}
				description={formatMessage(messages.description)}
				onRetry={onRetry}
			/>
		</Center>
	);
};

export default ErrorScreen;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Center = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
});
