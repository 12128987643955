import { defineMessages } from '@atlassian/jira-intl';

const textMessages = defineMessages({
	createPlanButton: {
		id: 'plans.dashboards.main.content.empty-screen.create-plan-button',
		defaultMessage: 'Create plan',
		description: 'Label for create plan button',
	},
	noPlansYetHeader: {
		id: 'plans.dashboards.main.content.empty-screen.no-plans-yet-header',
		defaultMessage: 'No plans yet',
		description: 'Heading for empty plans page',
	},
	getStartedMessage: {
		id: 'plans.dashboards.main.content.empty-screen.get-started-message',
		defaultMessage: 'Get started by making your first plan.',
		description: 'Description when no plans exist',
	},
	getStartedDescription: {
		id: 'plans.dashboards.main.content.empty-screen.get-started-description',
		defaultMessage: 'For tips on making the best plans, check out the {link} guide.',
		description: 'paragraph to get started with Plans',
	},
	getStartedWithPlans: {
		id: 'plans.dashboards.main.content.empty-screen.get-started-with-plans',
		defaultMessage: 'Get Started with Plans',
		description: 'link to doc',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	...textMessages,
};
