import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { useCreateNewPlanWizardDrawer } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/controllers';
import messages from './messages';

const CreatePlanButton = () => {
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const { formatMessage } = useIntl();
	const [{ items }] = usePlansItems();
	const isVisible = Boolean(items?.links?.createPlan.isVisible);

	const onClick = useCallback(() => {
		openCreateNewPlanWizardDrawer();
	}, [openCreateNewPlanWizardDrawer]);

	if (!isVisible) return null;

	return (
		<Button
			appearance="primary"
			onClick={onClick}
			testId="plans-main.ui.content.create-plan-button.button"
		>
			{formatMessage(messages.createPlanButton)}
		</Button>
	);
};

export default CreatePlanButton;
