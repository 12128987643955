import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'plans.dashboards.main.content.error-screen.header',
		defaultMessage: "We couldn't load your plans",
		description:
			'Heading for the error state (error screen) which is displayed for users on plans page in case plans fetching ended up with an error',
	},
	description: {
		id: 'plans.dashboards.main.content.error-screen.description',
		defaultMessage:
			'Give it another try. If you run into more trouble, contact your admin for help.',
		description:
			'Message for the error state (error screen) which is displayed for users on plans page in case plans fetching ended up with an error',
	},
});
