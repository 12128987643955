import { useEffect } from 'react';
import stripQueryParams from '@atlassian/jira-common-strip-query-params';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { useCreateNewPlanWizardDrawer } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/controllers';
import { useCreateSamplePlan } from '@atlassian/jira-portfolio-3-create-sample-plan/src/controllers';
import { useQueryParams } from '@atlassian/jira-software-router-utils/src/services/query-params/index.tsx';
import { CREATE_PLAN, CREATE_SAMPLE_PLAN } from '../common/constants';

export const useShowCreatePlansFromQueryParams = (): void => {
	const [createPlansParams] = useQueryParams(CREATE_PLAN, CREATE_SAMPLE_PLAN);
	const [{ items }] = usePlansItems();
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const [
		{ hasBeenInitialised: hasCreateSamplePlanModalBeenInitialised },
		{ open: openCreateSamplePlanModal },
	] = useCreateSamplePlan();

	const shouldShowCreateSamplePlanModal =
		createPlansParams[CREATE_SAMPLE_PLAN] === 'true' &&
		createPlansParams[CREATE_PLAN] !== 'true' &&
		hasCreateSamplePlanModalBeenInitialised &&
		items?.links?.createSamplePlan?.isVisible;

	const shouldShowCreateNewPlanWizard =
		createPlansParams[CREATE_PLAN] === 'true' && items?.links?.createPlan?.isVisible;

	useEffect(() => {
		const handleOpenCreatePlanQueryParams = () => {
			stripQueryParams([CREATE_PLAN, CREATE_SAMPLE_PLAN]);
			if (shouldShowCreateNewPlanWizard) {
				openCreateNewPlanWizardDrawer();
			} else if (shouldShowCreateSamplePlanModal) {
				openCreateSamplePlanModal();
			}
		};
		handleOpenCreatePlanQueryParams();
	}, [
		openCreateSamplePlanModal,
		shouldShowCreateNewPlanWizard,
		openCreateNewPlanWizardDrawer,
		shouldShowCreateSamplePlanModal,
	]);
};
