import React from 'react';
import { styled } from '@compiled/react';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { EntityCellProps, Plan } from '../../../../common/types';
import messages from './messages';

const LastUpdatedCell = ({ entity }: EntityCellProps) => {
	const { formatDate } = useIntl();

	if (!entity) return null;

	const plan: Plan = entity;

	return plan.lastCommittedTimestamp ? (
		<TextCell>{formatDate(plan.lastCommittedTimestamp * 1000)}</TextCell>
	) : null;
};

export const Heading = () => {
	const { formatMessage } = useIntl();

	const title = formatMessage(messages.planLastUpdatedHeading);
	const tooltip = formatMessage(messages.planLastUpdatedTooltip);

	return (
		<HeadingContainer>
			{fg('all-plans-table-header-tooltip-a11y') ? (
				formatMessage(messages.planLastUpdatedHeadingNew)
			) : (
				<>
					{title}{' '}
					<Tooltip content={tooltip}>
						{(tooltipProps) => (
							<span
								tabIndex={0}
								role="button"
								{...tooltipProps}
								aria-expanded={!!tooltipProps['aria-describedby']}
							>
								<EditorInfoIcon
									label={formatMessage(messages.planLastUpdatedInfoIconLabel)}
									size="small"
									testId="plans-main.ui.content.cells.last-updated-cell.editor-info-icon"
								/>
							</span>
						)}
					</Tooltip>
				</>
			)}
		</HeadingContainer>
	);
};

export default LastUpdatedCell;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TextCell = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'row',
	width: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingContainer = styled.div({
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
});
