import React, { type MouseEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { useCreateSamplePlan } from '@atlassian/jira-portfolio-3-create-sample-plan/src/controllers';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

const CreateSamplePlanButton = () => {
	const [, { open }] = useCreateSamplePlan();
	const { formatMessage } = useIntl();
	const [{ items }] = usePlansItems();
	const isVisible = Boolean(items?.links?.createSamplePlan?.isVisible);

	const onClick = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_e: MouseEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'plansDirectoryCreateSamplePlan');
			open();
		},
		[open],
	);

	if (!isVisible) return null;

	return <Button onClick={onClick}>{formatMessage(messages.createDemoPlanButton)}</Button>;
};

export default CreateSamplePlanButton;
