import type { SortDirection } from '@atlassian/jira-common-constants/src/sort-directions';
import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import type {
	TableConfigurationNext as GenericTableConfigurationNext,
	EntityCellPropsNext as GenericEntityCellPropsNext,
	DataProvider as GenericDataProvider,
	HeaderConfiguration as GenericHeaderConfiguration,
} from '@atlassian/jira-directory-base/src/model';
import type {
	ARCHIVE,
	DELETE,
	DUPLICATE,
	TRASH,
} from '@atlassian/jira-directory-base/src/model/operation-types';

export const TITLE = 'title' as const;
export const CREATOR = 'creator' as const;
export const LEAD = 'lead' as const;
export const LAST_COMMITTED_TIMESTAMP = 'lastCommittedTimestamp' as const;
export const FAVOURITE = 'favourite' as const;
export const STATUS_CHANGED_TIMESTAMP = 'statusChangedTimestamp' as const;
export const ACTIVE_STATUS = 'active' as const;
export const ARCHIVED_STATUS = 'archived' as const;
export const TRASHED_STATUS = 'trashed' as const;

export type Timestamp = number;

export type PlanUser = {
	title: string;
	email: string;
	avatarUrl: string;
	jiraUserId: string;
	accountId: string;
};

export type PlanStatus = typeof ACTIVE_STATUS | typeof ARCHIVED_STATUS | typeof TRASHED_STATUS;

export type Plan = {
	id: number;
	title: string;
	readOnly: boolean;
	livePlan: boolean;
	lastCommittedTimestamp: Timestamp;
	creator?: PlanUser;
	lead?: PlanUser;
	favourite?: boolean;
	planStatus?: PlanStatus;
	statusChangedTimestamp?: Timestamp;
};

export type SortField =
	| typeof TITLE
	| typeof CREATOR
	| typeof LEAD
	| typeof LAST_COMMITTED_TIMESTAMP
	| typeof FAVOURITE
	| typeof STATUS_CHANGED_TIMESTAMP;

export type Operations = typeof DELETE | typeof DUPLICATE | typeof ARCHIVE | typeof TRASH;

export type Query = {
	page: number;
	sortField: SortField;
	sortDirection: SortDirection;
	filter: Filter | undefined;
};

export type GenericQuery<TSortField, TFilter> = {
	page: number;
	sortField: TSortField;
	sortDirection: SortDirection;
	filter: TFilter | undefined;
};

export type DataProvider = GenericDataProvider<Plan>;

export type HeaderConfiguration = GenericHeaderConfiguration<Filter>;

export type TableConfiguration = GenericTableConfigurationNext<
	Operations,
	SortField,
	Plan['id'],
	Plan
>;

export type EntityCellProps = GenericEntityCellPropsNext<Operations, Plan['id'], Plan>;
