import React, { useCallback } from 'react';
import { FavoriteCellView } from '@atlassian/jira-common-directory-v2/src/components/favourite-cell-v2/view.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { EntityCellProps } from '../../../../common/types';
import { usePlans } from '../../../../controllers';

const FavoriteCell = ({ id, entity }: EntityCellProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isPlanFavorited, isPlanPending, changeFavorite } = usePlans();
	const planId = Number(id);
	const isFavorite = isPlanFavorited(planId);
	const isPending = isPlanPending(id);

	const handleClick = useCallback(() => {
		changeFavorite(planId, !isFavorite);

		const event = isFavorite
			? PRODUCT_ANALYTICS_EVENT_NAMES.UNSTAR_PLAN
			: PRODUCT_ANALYTICS_EVENT_NAMES.STAR_PLAN;
		const [actionSubject, action] = event.split(' ');
		fireTrackAnalytics(createAnalyticsEvent({ action, actionSubject }), event);
	}, [changeFavorite, planId, createAnalyticsEvent, isFavorite]);

	return (
		<FavoriteCellView
			isSmall={false}
			isShownInList
			isFavorite={isFavorite}
			favoriteItemName={entity?.title}
			onClick={handleClick}
			pending={isPending}
		/>
	);
};

export default FavoriteCell;
