import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import type {
	FilterToParamTransformer,
	ParamToFilterTransformer,
} from '@atlassian/jira-common-directory-v2/src/query-param-adapter/model';

export const filterToParams: FilterToParamTransformer<Filter> = ({ text }: Filter = {}) => ({
	name: text != null ? text : undefined,
});

export const filterFromParams: ParamToFilterTransformer<Filter> = (params) => ({
	text: params.name || params.searchName || undefined,
});
