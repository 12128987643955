import React, { useEffect } from 'react';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src';
import { useIntl } from '@atlassian/jira-intl';
import PlansDirectory from '@atlassian/jira-plans-main/src/ui/index.tsx';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { PageLayout } from '@atlassian/jira-spa-apps-page-layout/src/page/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { addApdexToAll } from '@atlassian/react-ufo/interaction-metrics';
import messages from './messages';
import type { Props } from './types';

const PlansDirectoryPage = ({ Updater, onQueryChanged, query }: Props) => {
	const tenantContext = useTenantContext();
	const { formatMessage } = useIntl();

	useEffect(() => {
		addApdexToAll({ key: 'plans-directory', stopTime: performance.now() });
	}, []);

	return (
		<PageLayout>
			<ReportErrors id="unhandled" packageName="jiraSpaAppsDirectoriesPlans" teamName="otter">
				<Updater />

				<DocumentTitle title={formatMessage(messages.pageTitle)} />
				<PlansDirectory
					locale={tenantContext.locale}
					onQueryChanged={onQueryChanged}
					query={query}
				/>
			</ReportErrors>
		</PageLayout>
	);
};

export default AnalyticsSource('plans', SCREEN)(PlansDirectoryPage);
