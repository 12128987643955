import React from 'react';
import UserCell from '@atlassian/jira-common-directory-v2/src/components/user-cell';
import type { EntityCellProps, Plan } from '../../../../common/types';
import { transformPlanUser } from '../../../../services';

const CreatorCell = ({ entity }: EntityCellProps) => {
	if (!entity) return null;

	const plan: Plan = entity;

	if (!plan.creator) {
		return null;
	}

	// @ts-expect-error - Property 'onClick' is missing in type '{ user: User; }' but required in type 'Props'.
	return <UserCell user={transformPlanUser(plan.creator)} />;
};

export default CreatorCell;
