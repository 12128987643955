import { ConnectionHandler, type RecordSourceSelectorProxy } from 'relay-runtime';
import RelayDataID from '@atlassian/relay-data-id';

type ACTIONS = 'ADD' | 'REMOVE';

type UpdateRelayStoreParams = {
	action: ACTIONS;
	cloudId: string;
	entityId: string;
	store: RecordSourceSelectorProxy;
};

/**
 * Updates the relay store for favorites.
 *
 * @param {UpdateRelayStoreParams} params - The parameters for updating the relay store.
 * @param {action} params.action - The action that needs to be performed.
 * @param {string} params.cloudId - The cloud ID.
 * @param {string} params.entityId - The entity ID.
 * @param {RecordSourceSelectorProxy} params.store - The Relay store.
 *
 * If `isFavourite` is false, the function finds the existing edge in favorites that matches the `entityId`,
 * and if found, it removes the edge from the favorites.
 */
export const updateRelayStore = ({ cloudId, store, entityId, action }: UpdateRelayStoreParams) => {
	const entityTypeName = 'JiraPlan';
	const nodeId = RelayDataID({ id: entityId }, entityTypeName) ?? '';
	const node = store.get(nodeId);

	// Exit if we can't find the relevant node or favorite connection
	if (!node) {
		return;
	}

	/**
	 * Make sure the "first: 15" below matches with the firstCount in the graphql query written in the
	 * file below. Else this will not work.
	 *
	 * jira/src/packages/navigation-apps/sidebar-nav4/plans/src/ui/plans/common/plans-list/PlansList.tsx
	 */
	const jiraRecord = store.getRoot().getLinkedRecord('jira');
	const favorites = jiraRecord?.getLinkedRecord('favourites', {
		cloudId,
		filter: { type: 'PLAN' },
		first: 15,
	});
	const numOfFavouritePlans = favorites?.getLinkedRecords('edges')?.length || 0;

	/**
	 * Make sure the "first: 20" below matches with the firstCount in the graphql query written in the
	 * file below. Else this will not work.
	 *
	 * jira/src/packages/navigation-apps/sidebar-nav4/plans/src/ui/plans/common/plans-list/PlansList.tsx
	 */
	const recents = jiraRecord?.getLinkedRecord('recentItems', {
		cloudId,
		types: ['PLAN'],
		first: 20,
	});
	const numOfRecentPlans = recents?.getLinkedRecords('edges')?.length || 0;

	switch (action) {
		case 'ADD': {
			const isFavorite = node.getLinkedRecord('favouriteValue')?.getValue('isFavourite');
			/**
			 * Max length for favorite plans mentioned in the following file is 15.
			 * jira/src/packages/navigation-apps/sidebar-nav4/plans/src/ui/plans/common/plans-list/PlansList.tsx
			 */
			if (isFavorite && favorites && numOfFavouritePlans < 15) {
				const edge = ConnectionHandler.createEdge(store, favorites, node, 'JiraFavouriteEdge');
				ConnectionHandler.insertEdgeBefore(favorites, edge);
			}

			/**
			 * Max length for recent plans mentioned in the following file is 20.
			 * jira/src/packages/navigation-apps/sidebar-nav4/plans/src/ui/plans/common/plans-list/PlansList.tsx
			 */
			if (recents && numOfRecentPlans < 20) {
				const edge = ConnectionHandler.createEdge(store, recents, node, 'JiraSearchableEntityEdge');
				ConnectionHandler.insertEdgeBefore(recents, edge);
			}
			break;
		}
		default:
		case 'REMOVE': {
			if (favorites) {
				ConnectionHandler.deleteNode(favorites, nodeId);
			}

			if (recents) {
				ConnectionHandler.deleteNode(recents, nodeId);
			}
			break;
		}
	}
};
