import type {
	ParamToSortFieldTransformer,
	SortFieldToParamTransformer,
} from '@atlassian/jira-common-directory-v2/src/query-param-adapter-legacy/model';
import type { SortField } from '@atlassian/jira-plans-main/src/common/types';
import {
	TITLE,
	CREATOR,
	LEAD,
	LAST_COMMITTED_TIMESTAMP,
	FAVOURITE,
} from '@atlassian/jira-plans-main/src/common/types.tsx';

const sortFieldMap: {
	[key: string]: SortField;
} = {
	[TITLE.toLowerCase()]: TITLE,
	[CREATOR.toLowerCase()]: CREATOR,
	[LEAD.toLowerCase()]: LEAD,
	[LAST_COMMITTED_TIMESTAMP.toLowerCase()]: LAST_COMMITTED_TIMESTAMP,
	[FAVOURITE.toLowerCase()]: FAVOURITE,
};

export const sortFieldToParam: SortFieldToParamTransformer<SortField> = (field) =>
	field.toLowerCase();

export const sortFieldFromParam: ParamToSortFieldTransformer<SortField> = (param) =>
	sortFieldMap[param] ? sortFieldMap[param] : null;
