/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import type { Props } from '@atlassian/jira-favourite-button-stateless/src/model/types.tsx';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';

const styles = cssMap({
	root: {
		display: 'flex',
		justifyContent: 'center',
		padding: token('space.050'),
	},
});

export function FavoriteCellView(props: Props) {
	return (
		<div css={styles.root}>
			<FavoriteButtonStateless {...props} />
		</div>
	);
}

FavoriteCellView.defaultProps = {
	isFavorite: undefined,
};
