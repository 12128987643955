import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	planLastUpdatedHeading: {
		id: 'plans.dashboards.main.content.cells.last-updated-cell.plan-last-updated-heading',
		defaultMessage: 'Last updated',
		description: 'Heading for last updated column',
	},
	planLastUpdatedHeadingNew: {
		id: 'plans.dashboards.main.content.cells.last-updated-cell.plan-last-updated-heading-new',
		defaultMessage: 'Last saved',
		description: 'Heading for last updated column',
	},
	planLastUpdatedTooltip: {
		id: 'plans.dashboards.main.content.cells.last-updated-cell.plan-last-updated-tooltip',
		defaultMessage:
			'Last updated is calculated based on last committed change in the plan. Committed changes are those that are reflected in Jira.',
		description: 'Tooltip for last updated heading',
	},
	planLastUpdatedInfoIconLabel: {
		id: 'plans.dashboards.main.content.cells.last-updated-cell.plan-last-updated-info-icon-label',
		defaultMessage: 'More info',
		description:
			'This text is used as aria-label for the info icon of last updated heading which is displayed inside the view all plans table',
	},
});
